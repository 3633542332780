@import 'default.less';
@import 'dark.less';
@import 'table.less';
@import 'variables.less';

:root,
*[data-prizm-theme='light'] {
  .prizmThemeVariablesV2();
  .prizmDefaultThemeV2();
  .prizmDefaultTableThemeV2();
}

*[data-prizm-theme='dark'] {
  .prizmDarkThemeV2();
  .prizmDefaultTableThemeV2();
  .prizmDarkTableThemeV2();
}
