@import 'fonts/fonts.less';

.prizm-font-title-h4 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.prizm-font-main-body-14,
.prizm-font-table-text-14 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.prizm-font-input-text-14px {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.prizm-font-main-body-12 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.prizm-font-btn-links-14 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}

.prizm-font-dynamic-title-h4 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
}

.prizm-font-input-text-helper {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
}

body {
  font-family: 'Inter';
}
