@import 'variables.less';

.prizmDarkTheme() {
  /* Color styles */
  --prizm-demo: var(--prizm-palette-g12);

  /* Shadow  */
  --prizm-shadow: var(--prizm-pallete-shadow-dark);
  --prizm-bg: var(--prizm-palette-g13);

  --prizm-g2-g11: var(--prizm-palette-g11);
  --prizm-g3-g11: var(--prizm-palette-g11);

  /* Text */
  --prizm-text-contrast: var(--prizm-palette-g2);
  --prizm-text-subscript: var(--prizm-palette-g7);
  --prizm-text-main: var(--prizm-palette-g5);
  /* Background */
  --prizm-bg-dashboard-forms: var(--prizm-palette-g13);
  --prizm-bg-body: var(--prizm-palette-g12);
  --prizm-bg-header: var(--prizm-palette-g11);
  /* Border */
  --prizm-border-forms: var(--prizm-palette-g9);
  --prizm-border-icon: var(--prizm-palette-g7);
  --prizm-border-widget: var(--prizm-palette-g11);
  /* Index */
  --prizm-index-fact: var(--prizm-palette-g1);
  --prizm-index-prediction: var(--prizm-palette-p1);
  --prizm-index-plan: var(--prizm-palette-b1);
  --prizm-index-good: var(--prizm-palette-gr1);
  --prizm-index-warning: var(--prizm-palette-y1);
  --prizm-index-danger: var(--prizm-palette-r1);
  --prizm-index-lock: var(--prizm-palette-lock-d);
  /* Lite */
  --prizm-lite-g730-20: var(--prizm-palette-g7-30);
  --prizm-lite-b130-20: var(--prizm-palette-b1-30);
  --prizm-lite-gr130-20: var(--prizm-palette-gr1-30);
  --prizm-lite-y130-20: var(--prizm-palette-y1-30);
  --prizm-lite-r130-20: var(--prizm-palette-r1-30);
  /* Grey */
  --prizm-grey-g13-g1: var(--prizm-palette-g13);
  --prizm-grey-g12-g2: var(--prizm-palette-g12);
  --prizm-grey-g11-g3: var(--prizm-palette-g11);
  --prizm-grey-g10-g4: var(--prizm-palette-g10);
  --prizm-grey-g9-g5: var(--prizm-palette-g9);
  --prizm-grey-g8-g6: var(--prizm-palette-g8);
  --prizm-grey-g7-g7: var(--prizm-palette-g7);
  --prizm-grey-g6-g8: var(--prizm-palette-g6);
  --prizm-grey-g5-g9: var(--prizm-palette-g5);
  --prizm-grey-g4-g10: var(--prizm-palette-g4);
  --prizm-grey-g3-g11: var(--prizm-palette-g3);
  --prizm-grey-g2-g12: var(--prizm-palette-g2);
  --prizm-grey-g1-g13: var(--prizm-palette-g1);
  /* Exeptions */
  --prizm-exceptions-white: var(--prizm-palette-g1);
  --prizm-link: var(--prizm-palette-lock-l);
  --prizm-exeptions-g1-b1: var(--prizm-palette-g1);

  --prizm-disabled: var(--prizm-grey-g11-g3);
  --prizm-disabled-outline: var(--prizm-grey-g11-g3);
  --prizm-disabled-text: var(--prizm-grey-g9-g5);
  --prizm-primary-ghost: transparent;

  --prizm-primary-ghost-hover: var(--prizm-palette-g11); //transparent;
  --prizm-primary-ghost-pressed: var(--prizm-lite-b130-20);

  --prizm-secondary-ghost-hover: var(--prizm-palette-g11); //transparent;
  --prizm-secondary-ghost-pressed: var(--prizm-lite-b130-20);

  --prizm-success-ghost-hover: var(--prizm-palette-g11); //transparent;
  --prizm-success-ghost-pressed: var(--prizm-lite-gr130-20);

  --prizm-warning-ghost-hover: var(--prizm-palette-g11); //transparent;
  //--prizm-warning-ghost-pressed: var(--prizm-lite-y130-20);

  --prizm-danger-ghost-hover: var(--prizm-palette-g11); //transparent;
  --prizm-disabled-ghost-text: var(--prizm-grey-g9-g5);
  //--prizm-danger-ghost-pressed: var(--prizm-lite-r130-20);

  --prizm-disabled-ghost-hover: var(--prizm-palette-g11); //transparent;
  --prizm-disabled-ghost-pressed: var(--prizm-grey-g11-g3);
}
