/*
Link - https://www.figma.com/file/lR03OmslN1ngsyglw65gKK/%F0%9F%8E%A8-Light-theme-V2?node-id=1407%3A16584&t=ITRL3bjTR3nj2syW-0
*/
.prizmThemeVariablesV2 () {
  --prizm-v2-palette-gray-base-0: #ffffff;
  --prizm-v2-palette-gray-base-25: #f9f9fb;
  --prizm-v2-palette-gray-base-100: #e6e8ef;
  --prizm-v2-palette-gray-base-200: #ced1df;
  --prizm-v2-palette-gray-base-300: #b7bbcd;
  --prizm-v2-palette-gray-base-400: #a0a5ba;
  --prizm-v2-palette-gray-base-500: #8b90a7;
  --prizm-v2-palette-gray-base-600: #73788e;
  --prizm-v2-palette-gray-base-700: #5c6075;
  --prizm-v2-palette-gray-base-800: #45495a;
  --prizm-v2-palette-gray-base-875: #2b2e3b;
  --prizm-v2-palette-gray-base-900: #2f323f;
  --prizm-v2-palette-gray-base-1000: #1a1c24;

  --prizm-v2-palette-gray-hover-50: #f2f3f6;
  --prizm-v2-palette-gray-hover-150: #dadde7;
  --prizm-v2-palette-gray-hover-250: #c3c7d7;
  --prizm-v2-palette-gray-hover-350: #aeb2c6;
  --prizm-v2-palette-gray-hover-450: #989db3;
  --prizm-v2-palette-gray-hover-550: #8489a1;
  --prizm-v2-palette-gray-hover-650: #787d94;
  --prizm-v2-palette-gray-hover-750: #62667c;
  --prizm-v2-palette-gray-hover-850: #4c5062;
  --prizm-v2-palette-gray-hover-950: #373a49;
  --prizm-v2-palette-gray-hover-1050: #23252f;

  --prizm-v2-palette-blue-100: #e7f1ff;
  --prizm-v2-palette-blue-200: #bad7ff;
  --prizm-v2-palette-blue-300: #8dbbff;
  --prizm-v2-palette-blue-400: #609dff;
  --prizm-v2-palette-blue-500: #337eff;
  --prizm-v2-palette-blue-600: #3870cc;
  --prizm-v2-palette-blue-700: #39609d;
  --prizm-v2-palette-blue-800: #354e73;
  --prizm-v2-palette-blue-900: #2d3a4e;

  --prizm-v2-palette-green-100: #eaf5e9;
  --prizm-v2-palette-green-200: #c1e2c1;
  --prizm-v2-palette-green-300: #99d09a;
  --prizm-v2-palette-green-400: #71be73;
  --prizm-v2-palette-green-500: #49ab4d;
  --prizm-v2-palette-green-600: #468a48;
  --prizm-v2-palette-green-700: #406b41;
  --prizm-v2-palette-green-800: #374f38;
  --prizm-v2-palette-green-900: #2c362c;

  --prizm-v2-palette-red-100: #fde8ea;
  --prizm-v2-palette-red-200: #fabec1;
  --prizm-v2-palette-red-300: #f79598;
  --prizm-v2-palette-red-400: #f46b6d;
  --prizm-v2-palette-red-500: #f14141;
  --prizm-v2-palette-red-600: #c14344;
  --prizm-v2-palette-red-700: #954243;
  --prizm-v2-palette-red-800: #6d3b3d;
  --prizm-v2-palette-red-900: #4a3132;

  --prizm-v2-palette-orange-100: #fff1e0;
  --prizm-v2-palette-orange-200: #ffd8a8;
  --prizm-v2-palette-orange-300: #ffc170;
  --prizm-v2-palette-orange-400: #ffab38;
  --prizm-v2-palette-orange-500: #ff9800;
  --prizm-v2-palette-orange-600: #c47c14;
  --prizm-v2-palette-orange-700: #916222;
  --prizm-v2-palette-orange-800: #654b27;
  --prizm-v2-palette-orange-900: #403526;

  --prizm-v2-palette-purple-100: #edecf7;
  --prizm-v2-palette-purple-200: #cac6ea;
  --prizm-v2-palette-purple-300: #a59ddb;
  --prizm-v2-palette-purple-400: #8578cd;
  --prizm-v2-palette-purple-500: #6353bf;
  --prizm-v2-palette-purple-600: #5a4e9c;
  --prizm-v2-palette-purple-700: #4e467b;
  --prizm-v2-palette-purple-800: #403c5c;
  --prizm-v2-palette-purple-900: #312f40;

  --prizm-v2-palette-teal-100: #e0f8fa;
  --prizm-v2-palette-teal-200: #a6eaf1;
  --prizm-v2-palette-teal-300: #71dbe9;
  --prizm-v2-palette-teal-400: #37cae1;
  --prizm-v2-palette-teal-500: #00badb;
  --prizm-v2-palette-teal-600: #0d92a9;
  --prizm-v2-palette-teal-700: #167382;
  --prizm-v2-palette-teal-800: #1b555e;
  --prizm-v2-palette-teal-900: #1a393d;

  --prizm-v2-palette-yellow-100: #fcf4e3;
  --prizm-v2-palette-yellow-200: #f4e0a8;
  --prizm-v2-palette-yellow-300: #efcf70;
  --prizm-v2-palette-yellow-400: #e7bf3a;
  --prizm-v2-palette-yellow-500: #e2b103;
  --prizm-v2-palette-yellow-600: #b18e10;
  --prizm-v2-palette-yellow-700: #896f1a;
  --prizm-v2-palette-yellow-800: #62521d;
  --prizm-v2-palette-yellow-900: #40371c;

  --prizm-v2-palette-pink-100: #fae9f5;
  --prizm-v2-palette-pink-200: #f2cae5;
  --prizm-v2-palette-pink-300: #eaa8d3;
  --prizm-v2-palette-pink-400: #e183bf;
  --prizm-v2-palette-pink-500: #da62ac;
  --prizm-v2-palette-pink-600: #b25c91;
  --prizm-v2-palette-pink-700: #8b5075;
  --prizm-v2-palette-pink-800: #68445b;
  --prizm-v2-palette-pink-900: #493642;

  --prizm-v2-gray-base-0: #ffffff;
  --prizm-v2-gray-base-100: #e6e8ef;
  --prizm-v2-gray-base-200: #ced1df;
  --prizm-v2-gray-base-300: #b7bbcd;
  --prizm-v2-gray-base-400: #a0a5ba;
  --prizm-v2-gray-base-500: #8b90a7;
  --prizm-v2-gray-base-600: #73788e;
  --prizm-v2-gray-base-700: #5c6075;
  --prizm-v2-gray-base-800: #45495a;
  --prizm-v2-gray-base-900: #2f323f;
  --prizm-v2-gray-base-1000: #1a1c24;

  --prizm-v2-gray-hover-50: #f2f3f6;
  --prizm-v2-gray-hover-150: #dadde7;
  --prizm-v2-gray-hover-250: #c3c7d7;
  --prizm-v2-gray-hover-350: #aeb2c6;
  --prizm-v2-gray-hover-450: #989db3;
  --prizm-v2-gray-hover-550: #8489a1;
  --prizm-v2-gray-hover-650: #787d94;
  --prizm-v2-gray-hover-750: #62667c;
  --prizm-v2-gray-hover-850: #4c5062;
  --prizm-v2-gray-hover-950: #373a49;
  --prizm-v2-gray-hover-1050: #23252f;

  --prizm-v2-panel-transparent: rgba(255, 255, 255, 0);
  --prizm-v2-status-primary: #337eff;
  --prizm-v2-status-success: #49ab4d;
  --prizm-v2-status-warning: #ff9800;
  --prizm-v2-status-danger: #f14141;
  --prizm-v2-content-iocn: #8b90a7;
}
