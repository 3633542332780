/*
Link - https://www.figma.com/file/lR03OmslN1ngsyglw65gKK/%F0%9F%8E%A8-Light-theme-V2?node-id=1407%3A16584&t=ITRL3bjTR3nj2syW-0
*/
.prizmDarkThemeV2() {
  --prizm-v2-preset-background-body: #ffffff;
  --prizm-v2-background-body-alt: #45495a;
  --prizm-v2-background-dashboard: #1a1c24;
  --prizm-v2-background-body: #2f323f;
  --prizm-v2-border-widget: #5c6075;
  --prizm-v2-border-forms: #73788e;
  --prizm-v2-preset-content-link: #8dbbff;

  // table
  --prizm-v2-table-zebra: #2f323f;

  --prizm-v2-table-text-default: var(--prizm-v2-gray-base-0);
  --prizm-v2-table-background-default-hover: var(--prizm-v2-gray-hover-950);

  --prizm-v2-table-background-selected: var(--prizm-v2-status-primary);
  --prizm-v2-table-text-selected: var(--prizm-v2-gray-base-0);

  --prizm-v2-table-text-danger: var(--prizm-v2-palette-red-400);
  --prizm-v2-table-background-danger: var(--prizm-v2-palette-red-900);
  --prizm-v2-table-background-danger-hover: var(--prizm-v2-palette-red-800);

  --prizm-v2-table-text-warning: var(--prizm-v2-palette-orange-400);
  --prizm-v2-table-background-warning: var(--prizm-v2-palette-orange-900);
  --prizm-v2-table-background-warning-hover: var(--prizm-v2-palette-orange-800);

  --prizm-v2-table-text-success: var(--prizm-v2-palette-green-400);
  --prizm-v2-table-background-success: var(--prizm-v2-palette-green-900);
  --prizm-v2-table-background-success-hover: var(--prizm-v2-palette-green-800);

  --prizm-v2-preset-background-primary: --prizm-v2-palette-gray-base-900;
  --prizm-v2-preset-background-secondary: --prizm-v2-palette-gray-base-1000;
  --prizm-v2-preset-background-tertiary: --prizm-v2-palette-gray-base-1000;
  --prizm-v2-preset-background-primary-revers: --prizm-v2-palette-gray-base-100;

  --prizm-v2-preset-border-focus: --prizm-v2-palette-blue-200;
  --prizm-v2-preset-border-primary: --prizm-v2-palette-gray-base-700;
  --prizm-v2-preset-border-secondary: --prizm-v2-palette-gray-base-700;

  --prizm-v2-preset-table-zebra: --prizm-v2-palette-gray-base-875;
  --prizm-v2-preset-table-header: --prizm-v2-palette-gray-base-800;
  --prizm-v2-preset-table-border-cell: --prizm-v2-palette-gray-base-700;
  --prizm-v2-preset-table-cell-success: --prizm-v2-palette-green-900;
  --prizm-v2-preset-table-cell-warning: --prizm-v2-palette-orange-900;
  --prizm-v2-preset-table-cell-danger: --prizm-v2-palette-red-900;
  --prizm-v2-preset-table-border-cell-success: --prizm-v2-palette-green-800;
  --prizm-v2-preset-table-border-cell-warning: --prizm-v2-palette-orange-800;
  --prizm-v2-preset-table-border-cell-danger: --prizm-v2-palette-red-800;

  --prizm-v2-preset-panel-transparent: rgba(0, 0, 0, 0);
  --prizm-v2-preset-panel-solid: --prizm-v2-palette-gray-base-900;

  --prizm-v2-preset-form-surface: --prizm-v2-palette-gray-base-875;
  --prizm-v2-preset-form-border: --prizm-v2-palette-gray-base-700;

  --prizm-v2-preset-control-surface: --prizm-v2-palette-gray-base-875;
  --prizm-v2-preset-control-border: --prizm-v2-palette-gray-base-700;

  --prizm-v2-preset-content-primary: --prizm-v2-palette-gray-base-0;
  --prizm-v2-preset-content-secondary: --prizm-v2-palette-gray-base-200;
  --prizm-v2-preset-content-tetreary: --prizm-v2-palette-gray-base-400;
  --prizm-v2-preset-content-revers: --prizm-v2-palette-gray-base-900;
  --prizm-v2-preset-content-disable: --prizm-v2-palette-gray-base-600;

  --prizm-v2-preset-status-primary: --prizm-v2-palette-blue-400;
  --prizm-v2-preset-status-primary-lite: --prizm-v2-palette-blue-800;
  --prizm-v2-preset-status-secondary: --prizm-v2-palette-gray-base-400;
  --prizm-v2-preset-status-secondary-lite: --prizm-v2-palette-gray-base-800;
  --prizm-v2-preset-status-success: --prizm-v2-palette-green-400;
  --prizm-v2-preset-status-success-lite: --prizm-v2-palette-green-800;
  --prizm-v2-preset-status-warning: --prizm-v2-palette-orange-400;
  --prizm-v2-preset-status-warning-lite: --prizm-v2-palette-orange-800;
  --prizm-v2-preset-status-danger: --prizm-v2-palette-red-400;
  --prizm-v2-preset-status-danger-lite: --prizm-v2-palette-red-800;
  --prizm-v2-preset-status-disable: --prizm-v2-palette-gray-base-875;

  --prizm-v2-preset-additional-status-purple: --prizm-v2-palette-purple-400;
  --prizm-v2-preset-additional-status-purple-lite: --prizm-v2-palette-purple-800;
  --prizm-v2-preset-additional-status-teal: --prizm-v2-palette-teal-400;
  --prizm-v2-preset-additional-status-teal-lite: --prizm-v2-palette-teal-800;
  --prizm-v2-preset-additional-status-yellow: --prizm-v2-palette-yellow-400;
  --prizm-v2-preset-additional-status-yellow-lite: --prizm-v2-palette-yellow-800;
  --prizm-v2-preset-additional-status-pink: --prizm-v2-palette-pink-400;
  --prizm-v2-preset-additional-status-pink-lite: --prizm-v2-palette-pink-800;

  --prizm-v2-state-table-header-hover: --prizm-v2-palette-gray-hover-850;
  --prizm-v2-state-table-border-cell-selected: --prizm-v2-palette-gray-base-600;
  --prizm-v2-state-table-row-hover: --prizm-v2-palette-gray-hover-950;
  --prizm-v2-state-table-row-selected: --prizm-v2-palette-blue-800;
  --prizm-v2-state-table-border-row-selected: --prizm-v2-palette-blue-700;
  --prizm-v2-state-table-row-success-hover: --prizm-v2-palette-green-800;
  --prizm-v2-state-table-border-row-success-hover: --prizm-v2-palette-green-700;
  --prizm-v2-state-table-row-warning-hover: --prizm-v2-palette-orange-800;
  --prizm-v2-state-table-border-row-warning-hover: --prizm-v2-palette-orange-700;
  --prizm-v2-state-table-row-danger-hover: --prizm-v2-palette-red-800;
  --prizm-v2-state-table-border-row-danger-hover: --prizm-v2-palette-red-700;

  --prizm-v2-state-button-primary-hover: --prizm-v2-palette-blue-300;
  --prizm-v2-state-button-primary-active: --prizm-v2-palette-blue-400;
  --prizm-v2-state-button-secondary-hover: --prizm-v2-palette-gray-base-300;
  --prizm-v2-state-button-secondary-active: --prizm-v2-palette-gray-base-400;
  --prizm-v2-state-button-success-hover: --prizm-v2-palette-green-300;
  --prizm-v2-state-button-success-active: --prizm-v2-palette-green-400;
  --prizm-v2-state-button-warning-hover: --prizm-v2-palette-orange-300;
  --prizm-v2-state-button-warning-active: --prizm-v2-palette-orange-400;
  --prizm-v2-state-button-danger-hover: --prizm-v2-palette-red-300;
  --prizm-v2-state-button-danger-active: --prizm-v2-palette-red-400;
  --prizm-v2-state-button-ghost-hover: --prizm-v2-palette-gray-hover-950;

  --prizm-v2-state-form-border-hover: --prizm-v2-palette-gray-base-550;

  --prizm-v2-state-content-link-hover: --prizm-v2-palette-blue-300;
  --prizm-v2-state-content-link-active: --prizm-v2-palette-blue-400;
  --prizm-v2-state-content-link-visited: --prizm-v2-palette-blue-600;

  --prizm-v2-state-control-active: --prizm-v2-palette-blue-400;
  --prizm-v2-state-control-active-hover: --prizm-v2-palette-blue-300;
  --prizm-v2-state-control-active-disable: --prizm-v2-palette-blue-600;
  --prizm-v2-state-control-border-hover: --prizm-v2-palette-gray-base-550;
}
