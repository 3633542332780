/*
Link - https://www.figma.com/file/lR03OmslN1ngsyglw65gKK/%F0%9F%8E%A8-Light-theme-V2?node-id=1407%3A16584&t=ITRL3bjTR3nj2syW-0
*/
.prizmDarkTableThemeV2() {
  --prizm-v2-table-zebra: #2f323f;

  --prizm-v2-table-text-default: var(--prizm-v2-gray-base-0);
  --prizm-v2-table-background-default: #20222b;
  --prizm-v2-table-background-default-hover: var(--prizm-v2-gray-hover-950);

  --prizm-v2-table-background-selected: var(--prizm-v2-palette-blue-800);
  --prizm-v2-table-text-selected: var(--prizm-v2-gray-base-0);

  --prizm-v2-table-text-danger: var(--prizm-v2-palette-red-400);
  --prizm-v2-table-background-danger: var(--prizm-v2-palette-red-900);
  --prizm-v2-table-background-danger-hover: var(--prizm-v2-palette-red-800);

  --prizm-v2-table-text-warning: var(--prizm-v2-palette-orange-400);
  --prizm-v2-table-background-warning: var(--prizm-v2-palette-orange-900);
  --prizm-v2-table-background-warning-hover: var(--prizm-v2-palette-orange-800);

  --prizm-v2-table-text-success: var(--prizm-v2-palette-green-400);
  --prizm-v2-table-background-success: var(--prizm-v2-palette-green-900);
  --prizm-v2-table-background-success-hover: var(--prizm-v2-palette-green-800);
}

.prizmDefaultTableThemeV2() {
  --prizm-v2-table-zebra: #f9f9fb;

  --prizm-v2-table-text-selected: var(--prizm-v2-gray-base-1000);
  --prizm-v2-table-background-selected: var(--prizm-v2-palette-blue-200);

  --prizm-v2-table-text-default: var(--prizm-v2-gray-base-1000);
  --prizm-v2-table-background-default: white;
  --prizm-v2-table-background-default-hover: var(--prizm-v2-gray-hover-150);

  --prizm-v2-table-text-danger: var(--prizm-v2-palette-red-600);
  --prizm-v2-table-background-danger: var(--prizm-v2-palette-red-100);
  --prizm-v2-table-background-danger-hover: var(--prizm-v2-palette-red-200);

  --prizm-v2-table-text-warning: var(--prizm-v2-palette-orange-600);
  --prizm-v2-table-background-warning: var(--prizm-v2-palette-orange-100);
  --prizm-v2-table-background-warning-hover: var(--prizm-v2-palette-orange-200);

  --prizm-v2-table-text-success: var(--prizm-v2-palette-green-600);
  --prizm-v2-table-background-success: var(--prizm-v2-palette-green-100);
  --prizm-v2-table-background-success-hover: var(--prizm-v2-palette-green-200);
}
