.prizmV3ThemeDarkPalette() {
  --prizm-v3-palette-0: #0f1115;
  --prizm-v3-palette-1200: #ffffff;
  --prizm-v3-palette-gray-h50: #181b22;
  --prizm-v3-palette-gray-100: #1e222a;
  --prizm-v3-palette-gray-h150: #222731;
  --prizm-v3-palette-gray-200: #252a35;
  --prizm-v3-palette-gray-h250: #292e3a;
  --prizm-v3-palette-gray-300: #2c313d;
  --prizm-v3-palette-gray-h350: #373e4e;
  --prizm-v3-palette-gray-400: #3f4759;
  --prizm-v3-palette-gray-h450: #4c556b;
  --prizm-v3-palette-gray-500: #545f77;
  --prizm-v3-palette-gray-h550: #646d84;
  --prizm-v3-palette-gray-600: #6e778c;
  --prizm-v3-palette-gray-h650: #7e8799;
  --prizm-v3-palette-gray-700: #8a91a2;
  --prizm-v3-palette-gray-h750: #9ba1af;
  --prizm-v3-palette-gray-800: #a7acb8;
  --prizm-v3-palette-gray-h850: #b7bcc6;
  --prizm-v3-palette-gray-900: #c3c7cf;
  --prizm-v3-palette-gray-h950: #d4d8dd;
  --prizm-v3-palette-gray-1000: #e0e3e7;
  --prizm-v3-palette-gray-h1050: #ebedef;
  --prizm-v3-palette-gray-1100: #f3f4f5;
  --prizm-v3-palette-gray-h1150: #f8f8f9;
  --prizm-v3-palette-blue-h50: #111a32;
  --prizm-v3-palette-blue-100: #132045;
  --prizm-v3-palette-blue-h150: #16234f;
  --prizm-v3-palette-blue-200: #182556;
  --prizm-v3-palette-blue-h250: #1a295d;
  --prizm-v3-palette-blue-300: #1b2b62;
  --prizm-v3-palette-blue-h350: #23387c;
  --prizm-v3-palette-blue-400: #28408d;
  --prizm-v3-palette-blue-h450: #304daa;
  --prizm-v3-palette-blue-500: #3555bd;
  --prizm-v3-palette-blue-h550: #3d63da;
  --prizm-v3-palette-blue-600: #436cee;
  --prizm-v3-palette-blue-h650: #587df7;
  --prizm-v3-palette-blue-700: #6689fd;
  --prizm-v3-palette-blue-h750: #7d9bfd;
  --prizm-v3-palette-blue-800: #8da7fd;
  --prizm-v3-palette-blue-h850: #a4b8fd;
  --prizm-v3-palette-blue-900: #b4c4fd;
  --prizm-v3-palette-blue-h950: #cad6fe;
  --prizm-v3-palette-blue-1000: #d9e2ff;
  --prizm-v3-palette-blue-h1050: #e7edff;
  --prizm-v3-palette-blue-1100: #f0f4ff;
  --prizm-v3-palette-blue-h1150: #f4f7fe;
  --prizm-v3-palette-green-h50: #161e10;
  --prizm-v3-palette-green-100: #1a260d;
  --prizm-v3-palette-green-h150: #1c2b0f;
  --prizm-v3-palette-green-200: #1e2e11;
  --prizm-v3-palette-green-h250: #203212;
  --prizm-v3-palette-green-300: #223512;
  --prizm-v3-palette-green-h350: #2a4513;
  --prizm-v3-palette-green-400: #2f5014;
  --prizm-v3-palette-green-h450: #366015;
  --prizm-v3-palette-green-500: #3b6a15;
  --prizm-v3-palette-green-h550: #3e7c13;
  --prizm-v3-palette-green-600: #498714;
  --prizm-v3-palette-green-h650: #5a9727;
  --prizm-v3-palette-green-700: #65a134;
  --prizm-v3-palette-green-h750: #7caf52;
  --prizm-v3-palette-green-800: #8cb966;
  --prizm-v3-palette-green-h850: #a3c784;
  --prizm-v3-palette-green-900: #b2d198;
  --prizm-v3-palette-green-h950: #c9deb6;
  --prizm-v3-palette-green-1000: #d8e7ca;
  --prizm-v3-palette-green-h1050: #e6f0dd;
  --prizm-v3-palette-green-1100: #eff6ea;
  --prizm-v3-palette-green-h1150: #f5faf2;
  --prizm-v3-palette-yellow-h50: #1f1a11;
  --prizm-v3-palette-yellow-100: #29200f;
  --prizm-v3-palette-yellow-h150: #302511;
  --prizm-v3-palette-yellow-200: #342912;
  --prizm-v3-palette-yellow-h250: #382d14;
  --prizm-v3-palette-yellow-300: #3b3015;
  --prizm-v3-palette-yellow-h350: #4b3d17;
  --prizm-v3-palette-yellow-400: #564518;
  --prizm-v3-palette-yellow-h450: #67521a;
  --prizm-v3-palette-yellow-500: #725b1c;
  --prizm-v3-palette-yellow-h550: #836a1e;
  --prizm-v3-palette-yellow-600: #8f7420;
  --prizm-v3-palette-yellow-h650: #a28322;
  --prizm-v3-palette-yellow-700: #af8d23;
  --prizm-v3-palette-yellow-h750: #c29d24;
  --prizm-v3-palette-yellow-800: #cea724;
  --prizm-v3-palette-yellow-h850: #e1b725;
  --prizm-v3-palette-yellow-900: #edc225;
  --prizm-v3-palette-yellow-h950: #f4d560;
  --prizm-v3-palette-yellow-1000: #f9e187;
  --prizm-v3-palette-yellow-h1050: #fbecb3;
  --prizm-v3-palette-yellow-1100: #fdf3d1;
  --prizm-v3-palette-yellow-h1150: #fef8e3;
  --prizm-v3-palette-orange-h50: #221913;
  --prizm-v3-palette-orange-100: #2e1f11;
  --prizm-v3-palette-orange-h150: #352312;
  --prizm-v3-palette-orange-200: #392513;
  --prizm-v3-palette-orange-h250: #3e2915;
  --prizm-v3-palette-orange-300: #422b16;
  --prizm-v3-palette-orange-h350: #553619;
  --prizm-v3-palette-orange-400: #623e1b;
  --prizm-v3-palette-orange-h450: #764b1e;
  --prizm-v3-palette-orange-500: #835420;
  --prizm-v3-palette-orange-h550: #986122;
  --prizm-v3-palette-orange-600: #a66923;
  --prizm-v3-palette-orange-h650: #bb7725;
  --prizm-v3-palette-orange-700: #c98027;
  --prizm-v3-palette-orange-h750: #de8e27;
  --prizm-v3-palette-orange-800: #ec9827;
  --prizm-v3-palette-orange-h850: #f0ae51;
  --prizm-v3-palette-orange-900: #f2bc6d;
  --prizm-v3-palette-orange-h950: #f6d09a;
  --prizm-v3-palette-orange-1000: #f8deb8;
  --prizm-v3-palette-orange-h1050: #fbe9d1;
  --prizm-v3-palette-orange-1100: #fdf1e2;
  --prizm-v3-palette-orange-h1150: #fef7ee;
  --prizm-v3-palette-red-h50: #271613;
  --prizm-v3-palette-red-100: #371912;
  --prizm-v3-palette-red-h150: #401c14;
  --prizm-v3-palette-red-200: #461e15;
  --prizm-v3-palette-red-h250: #4d2017;
  --prizm-v3-palette-red-300: #512218;
  --prizm-v3-palette-red-h350: #6a291e;
  --prizm-v3-palette-red-400: #7a2d22;
  --prizm-v3-palette-red-h450: #943427;
  --prizm-v3-palette-red-500: #a5382a;
  --prizm-v3-palette-red-h550: #c13f2f;
  --prizm-v3-palette-red-600: #d44332;
  --prizm-v3-palette-red-h650: #df5a46;
  --prizm-v3-palette-red-700: #e66a54;
  --prizm-v3-palette-red-h750: #e9836f;
  --prizm-v3-palette-red-800: #eb9481;
  --prizm-v3-palette-red-h850: #eeab99;
  --prizm-v3-palette-red-900: #f0baa9;
  --prizm-v3-palette-red-h950: #f4cec3;
  --prizm-v3-palette-red-1000: #f7dcd4;
  --prizm-v3-palette-red-h1050: #fae9e4;
  --prizm-v3-palette-red-1050: #fcf1ee;
  --prizm-v3-palette-red-h1150: #fdf7f5;
}
