.prizmThemeVariables () {
  --prizm-palette-g1: #ffffff;
  --prizm-palette-g2: #f4f6fb;
  --prizm-palette-g3: #d6dbe7;
  --prizm-palette-g4: #bfc6d7;
  --prizm-palette-g5: #a1a5b7;
  --prizm-palette-g6: #8d91a4;
  --prizm-palette-g7: #777b92;
  --prizm-palette-g8: #606580;
  --prizm-palette-g9: #50546b;
  --prizm-palette-g10: #404355;
  --prizm-palette-g11: #303340;
  --prizm-palette-g12: #20222b;
  --prizm-palette-g13: #13151c;
  --prizm-palette-b1: #337eff;
  --prizm-palette-b2: #0f62fe;
  --prizm-palette-b3: #003ed6;
  --prizm-palette-y1: #ff7c0a;
  --prizm-palette-y2: #d36300;
  --prizm-palette-y3: #d36300;
  --prizm-palette-r1: #f14141;
  --prizm-palette-r2: #da181e;
  --prizm-palette-r3: #ad1218;
  --prizm-palette-gr1: #49ab4d;
  --prizm-palette-gr2: #0c7733;
  --prizm-palette-p1: #a2e2ff;

  --prizm-palette-b1-20: #d6e5ff;
  --prizm-palette-y1-20: #ffe5ce;
  --prizm-palette-r1-20: #fcd9d9;
  --prizm-palette-gr1-20: #dbeedb;
  --prizm-palette-g7-20: #e4e5e9;

  --prizm-palette-b1-30: #1d3460;
  --prizm-palette-y1-30: #5a3417;
  --prizm-palette-r1-30: #562227;
  --prizm-palette-gr1-30: #23422b;
  --prizm-palette-g7-30: #31343f;

  --prizm-palette-lock-l: #99bfff;
  --prizm-palette-lock-d: #295095;

  --prizm-pallete-shadow-light: rgba(191, 198, 215, 0.5);
  --prizm-pallete-shadow-dark: rgba(0, 0, 0, 0.5);
}
